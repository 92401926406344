.modalWrapper {
  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0%;
  left: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modalContainer {
  position: absolute;
  z-index: 30;
  top: 50px;
  right: 20px;
  width: 380px;
  height: 194px;
  background: var(--white-color);
  box-shadow: 10px 10px 30px rgba(82, 85, 95, 0.4);
  border-radius: 30px;
  background-color: var(--add-bg-color);
}

.closeBtn {
  display: block;
  cursor: pointer;
  text-align: right;
  padding-right: 20px;
  padding-top: 20px;
}

.closeBtn:hover {
  color: var(--accent-color);
  font-weight: bolder;
}

.title {
  text-align: center;
  margin-top: 35px;
  margin-bottom: 32px;
  color: var(--black-text-color);
}

.buttons {
  text-align: center;
}

.yes {
  background-color: var(--accent-color);
  margin-right: 15px;
  padding: 12px 34px;
  border-radius: 16px;
  width: 125px;
  height: 44px;
  border: none;
  font-family: Roboto;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  cursor: pointer;
  color: var(--white-color);
}

.no {
  background-color: transparent;
  border: 2px solid var(--bg-color);
  margin-right: 15px;
  padding: 12px 34px;
  border-radius: 16px;
  width: 125px;
  height: 44px;
  font-family: Roboto;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  cursor: pointer;
}

.buttonStyles {
  width: 122px;
  height: 44px;

  background-color: #f5f6fb;
  border-radius: 16px;

  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  text-transform: uppercase;

  color: #52555f;
}

.buttonStyles:not(:last-child) {
  margin-right: 15px;
}

.buttonStyles:hover {
  color: #fff;
  background-color: var(--accent-color);
}
