button {
  border: transparent;
}

.logOutIcon:hover,
.logOutIcon:focus {
  color: var(--accent-color);
}

.wrapper {
  display: flex;
  align-items: center;
}

.logOutBtn {
  display: none;
}

.userIcon {
  margin-right: 10px;
  z-index: 2000;
  width: 32px;
  height: 32px;
  text-align: center;
  border-radius: 50%;
  position: relative;
}

.userName {
  display: none;
  border-right: 1px solid #e0e5eb;
}

@media screen and (min-width: 768px) {
  .userName {
    display: block;
    padding-right: 20px;

    font-size: 12px;
    line-height: 1.2;

    letter-spacing: 0.04em;

    color: var(--primary-text-color);
    border-right: 1px solid #e0e5eb;
  }

  .linkLogOut {
    padding-top: 0;
    padding-left: 20px;
  }

  .logOutIcon {
    display: none;
    padding-left: 20px;
  }

  .logOutBtn {
    display: block;
    background: transparent;
    padding-left: 20px;
  }
}

.logOutTextBtn {
  font-size: 12px;
  color: var(--primary-text-color);
  cursor: pointer;
  border-bottom: 1px solid var(--primary-text-color);
}

.logOutTextBtn:hover,
.logOutTextBtn:focus {
  color: var(--accent-color);
  border-color: var(--accent-color);
}
